import React, { useState } from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import image1 from "../images/pharma1.png";

const Npharma2 = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const { name, phone, message } = formData;
    if (name && phone && message) {
      alert("Applied successfully!");
      setFormData({ name: "", phone: "", message: "" }); // Reset form after submission
    } else {
      alert("Please fill out all the fields.");
    }
  };

  const handleExploreMoreClick = () => {
    window.location.href = "https://www.getmycollege.com/colleges?state=karnataka";
  };

  return (
    <Grid container spacing={2} p={2}>
      {/* Left Grid Item (Image Section) */}
      <Grid
        item
        xs={12}
        sm={7}
        md={8}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={image1}
          alt="Pharmacy College Banner"
          style={{
            width: "100%",
            height: "100%", // Ensure the image takes the full height of the grid
            objectFit: "cover", // Ensure the image covers the area without distortion
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
          }}
        />
        <div
          className="image-text-block"
          style={{
            position: "absolute",
            color: "white",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "20px",
            borderRadius: "8px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80%",
            width: "90%",
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              fontSize: "1.8rem",
            }}
          >
            Welcome to Pharmacy College
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: "20px",
              fontSize: "1rem",
            }}
          >
            Discover a world of opportunities in pharmaceutical education and research.
          </Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: "orange",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleExploreMoreClick}
          >
            Discover
          </Button>
        </div>
      </Grid>

      {/* Right Grid Item (Form Section) */}
      <Grid
        item
        xs={12}
        sm={5}
        md={4}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Centers the form vertically
          alignItems: "center", // Centers the form horizontally
          height: "100%", // Ensures the grid item fills the available height
          paddingTop: "30px", // Adds padding from the top of the grid
          paddingBottom: "30px", // Adds padding from the bottom of the grid
        }}
      >
        <div style={{ width: "100%", maxWidth: "400px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              marginBottom: "10px",
              textAlign: "center",
              paddingTop: "20px", // Add padding from the top to the text
            }}
          >
            Application Form
          </Typography>
          <Typography
            variant="body2"
            style={{
              marginBottom: "20px",
              textAlign: "center",
              color: "#555",
            }}
          >
            Join our esteemed pharmacy college and take the first step toward your career in pharmaceuticals.
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            style={{ marginBottom: "20px" }}
          />
          <TextField
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            fullWidth
            style={{ marginBottom: "20px" }}
          />
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={4}
            style={{ marginBottom: "20px" }}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            style={{
              backgroundColor: "orange",
              color: "white",
              textTransform: "none",
              width: "150px",
              alignSelf: "center", // This ensures the button is centered
              marginTop: "20px",
            }}
          >
            Submit
          </Button>
        </div>
      </Grid>

      {/* Responsive Styles for Mobile */}
      <style>
        {`
          @media (max-width: 600px) {
            .image-text-block {
              display: none; /* Hide the text block on mobile */
            }
          }
        `}
      </style>
    </Grid>
  );
};

export default Npharma2;
