import React from "react";
import { Grid, Typography, Card, CardContent, CardMedia, Button, Box, useMediaQuery } from "@mui/material";
import logo1 from "../images/msr.jpeg.jpg";
import logo2 from "../images/ab.jpg";
import logo3 from "../images/al-ameen.jpeg.jpg";
import logo4 from "../images/krupanidhi.jpeg.jpg";

const PharmacyColleges = () => {
    const colleges = [
        { id: 1, name: "MS Ramaiah College of Pharmacy", image: logo1, rating: 4.5 },
        { id: 2, name: "Acharya & BM Reddy College of Pharmacy", image: logo2, rating: 4.2 },
        { id: 3, name: "Al-Ameen College of Pharmacy", image: logo3, rating: 4.0 },
        { id: 4, name: "Krupanidhi College of Pharmacy", image: logo4, rating: 4.8 },
    ];

    const handleExploreMoreClick = () => {
        window.location.href = "https://www.getmycollege.com/colleges?state=karnataka";
    };

    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: isMobile ? "20px 15px" : "0 40px", // Added left and right padding
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    textAlign: "center",
                    marginBottom: "20px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontSize: isMobile ? "1.5rem" : "2rem",
                }}
            >
                Pharmacy Colleges
            </Typography>
            <Grid container spacing={3} justifyContent="center">
                {colleges.map((college) => (
                    <Grid item xs={12} sm={6} md={3} key={college.id}>
                        <Card
                            sx={{
                                maxWidth: 300,
                                margin: "auto",
                                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                                backgroundColor: "#ffffff",
                                borderRadius: "10px",
                                transition: "transform 0.3s ease, background-color 0.3s ease",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    backgroundColor: "#ecf0f1",
                                },
                            }}
                        >
                            <CardMedia
                                component="img"
                                height="150"
                                image={college.image}
                                alt={college.name}
                                sx={{
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            />
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: "#34495e",
                                    }}
                                >
                                    {college.name}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "10px",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            padding: "8px 20px",
                                            borderRadius: "25px",
                                            backgroundColor: "#3498db",
                                            "&:hover": {
                                                backgroundColor: "#2980b9",
                                            },
                                        }}
                                        onClick={handleExploreMoreClick}
                                    >
                                        Know More
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box
                sx={{
                    textAlign: "center",
                    marginTop: isMobile ? "20px" : "40px",
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{
                        backgroundColor: "#f39c12",
                        "&:hover": { backgroundColor: "#e67e22" },
                        marginTop: isMobile ? "10px" : "20px",
                        mb: isMobile && "30px",
                    }}
                    onClick={handleExploreMoreClick}
                >
                    Explore More
                </Button>
            </Box>
        </Box>
    );
};

export default PharmacyColleges;
