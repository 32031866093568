import React from "react";
import { Grid, Typography, Button, Box, Card, CardContent, CardMedia, useMediaQuery } from "@mui/material";

// Importing images
import hillsideImage from '../images/hill.jpg'; // Adjust the path as necessary
import manipalImage from '../images/mani.webp'; // Adjust the path as necessary
import rvImage from '../images/rr.webp'; // Adjust the path as necessary
import krupanidhiImage from '../images/kru.jpg'; // Adjust the path as necessary

const collegeData = [
    {
        name: "Hillside College of Pharmacy and Research Centre",
        image: hillsideImage,
    },
    {
        name: "Manipal College of Pharmaceutical Sciences",
        image: manipalImage,
    },
    {
        name: "RR College of Pharmacy",
        image: rvImage,
    },
    {
        name: "Krupanidhi College of Pharmacy",
        image: krupanidhiImage,
    }
];

const HillsideCollege = () => {
    const isMobile = useMediaQuery("(max-width:600px)"); // Check for mobile screens
    const handleExploreMoreClick = () => {
        window.location.href = "https://www.getmycollege.com/colleges?state=karnataka";
    };

    return (
        <Grid container spacing={4} sx={{ paddingLeft: isMobile ? "20px" : "40px" }}>
            {/* Left Grid Item: Front Page Message */}
            <Grid item xs={12} md={4} sx={{ 
                backgroundColor: "lightblue",
                color: "#fff", 
                padding: isMobile ? "40px 20px" : "40px 20px 40px 40px",
                textAlign: "center", 
                height: "auto",
                boxSizing: "border-box" // Prevents overlapping
            }}>
                <Typography 
                    variant="h3" 
                    gutterBottom 
                    sx={{
                        fontSize: isMobile ? "1.8rem" : "2.5rem",
                        color:'#333333',
                    }}
                >
                    Top Pharmacy Colleges in Bangalore
                </Typography>
                <Typography 
                    variant="body1" 
                    paragraph 
                    sx={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        color:'#333333',
                    }}
                >
                    Bangalore is home to some of India's top pharmacy colleges, renowned for their academic excellence and strong industry connections. The city offers a range of institutions where students can pursue various programs in pharmacy, from undergraduate to postgraduate levels.
                </Typography>
                <Typography 
                    variant="body1" 
                    paragraph 
                    sx={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        color:'#333333',
                    }}
                >
                    These colleges emphasize a mix of academic rigor, practical training, and research opportunities. Students benefit from well-equipped laboratories, experienced faculty members, and active collaborations with the pharmaceutical industry.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" sx={{ backgroundColor: "#fca311", color: "#000", padding: "10px 20px" }}onClick={handleExploreMoreClick}>
                        Explore Now
                    </Button>
                </Box>
            </Grid>

            {/* Right Grid Item: Colleges Cards */}
            <Grid item xs={12} md={8} sx={{ 
                backgroundColor: "#fff", 
                padding: isMobile ? "40px 20px" : "40px",
                boxSizing: "border-box" // Prevents overlapping
            }}>
                <Grid 
                    container 
                    spacing={4} 
                    justifyContent="center"
                    direction={isMobile ? "column" : "row"} // Stack cards vertically in mobile
                >
                    {collegeData.map((college, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Card sx={{ 
                                height: "100%", 
                                maxWidth: "350px", 
                                boxShadow: 3, 
                                margin: "auto", 
                                backgroundColor: "#f5f5f5", 
                                position: "relative", 
                                transition: "background-color 0.3s ease" 
                            }}>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={college.image}
                                    alt={`${college.name} Image`}
                                />
                                <CardContent>
                                    <Typography variant="h6" align="center">
                                        {college.name}
                                    </Typography>
                                </CardContent>

                                {/* Hover effect to display black transparent screen */}
                                <Box sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                                    opacity: 0,
                                    transition: "opacity 0.3s ease",
                                    "&:hover": {
                                        opacity: 1, // On hover, show the overlay
                                    }
                                }}>
                                    <Button variant="contained" sx={{ backgroundColor: "#fca311", color: "#000" }}
                                    onClick={handleExploreMoreClick}>
                                        Know More
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HillsideCollege;
