import React from 'react';
import { Typography, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Grid container sx={{ backgroundColor: "#000", padding: "20px 0", justifyContent: "center" }}>
      <Grid item>
        <Typography variant="body1" sx={{ color: "#fff", textAlign: "center" }}>
          Copyright © 2024 Pharmacy Colleges
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
