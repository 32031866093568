import React from "react";
import { Grid, Typography, Divider, Box, CardMedia } from "@mui/material";
import imageUrl from "../images/pharma2.png"; // Replace with your image path

const WhyChooseMe = () => {
  return (
    <Box sx={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        {/* Left Grid: Image */}
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            image={imageUrl}
            alt="Why Choose Me Image"
            sx={{
              height: "auto",
              width: "100%",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            }}
          />
        </Grid>

        {/* Right Grid: Text Content */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
            Why Choose Us?
          </Typography>

          {/* Divider with Orange Color */}
          <Divider sx={{ width: "100%", marginBottom: "20px", borderColor: "orange" }} />

          <Typography variant="body1" sx={{ color: "#34495e", lineHeight: 1.6, marginBottom: "20px", textAlign: "center" }}>
            I offer a unique set of skills and experiences that make me stand out in the field. I am passionate
            about what I do and always strive for excellence. With a strong foundation in both theoretical
            knowledge and practical skills, I am equipped to handle challenges and deliver high-quality results.
            I am committed to continuous learning and improvement, ensuring I stay at the forefront of my field.
            <br />
            <br />
            Over the years, I have honed my skills by working on various projects that have allowed me to build
            a deep understanding of my domain. I bring not just technical expertise, but also a passion for
            innovation and solving complex problems. Whether it's collaborating with a team or leading a project, 
            I always aim to inspire others and contribute towards shared goals.
            <br />
            <br />
            What sets me apart is my ability to adapt quickly and efficiently to new challenges. My problem-solving
            skills, combined with my perseverance, help me to face new challenges head-on and find creative solutions.
            I pride myself on being proactive, detail-oriented, and delivering work that exceeds expectations.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhyChooseMe;
