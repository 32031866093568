import React from 'react';
import './App.css';


import Pharma3 from './pharmacy/Pharma3';

import Npharm1 from './pharmacy/Npharm1';
import Npharma2 from './pharmacy/Npharma2';
import Npharma3 from './pharmacy/Npharma3';
import Npharma4 from './pharmacy/Npharma4';
import Footer from './pharmacy/Footer';


function App() {
  return (
   <div>
      
          <Npharm1/>
          <Npharma2/>
          <Npharma3/>
          <Pharma3/>
          <Npharma4/>
          <Footer/>
    </div>
  );
}


export default App;
