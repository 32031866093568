import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import pharmalogo from "../images/pharmacycollegesinbangalore.com.png";
import AutoPopup from "./Autopopup";

const Npharm1 = () => {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to manage drawer visibility
  const isMobile = useMediaQuery("(max-width:600px)"); // Check if the screen size is mobile

  const handleNavigation = (url) => {
    window.location.href = url;
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open); // Toggle the drawer open state
  };

  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };

  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);

    return () => clearTimeout(timeout); // Cleanup timeout
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      style={{
        backgroundColor: "#03A9F4",
        color: "black",
        padding: "10px 20px",
      }}
    >
      {/* Logo Section */}
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={pharmalogo}
          alt="Logo"
          style={{
            height: isMobile ? "50px" : "100px", // Larger size for desktop, smaller for mobile
            marginRight: "15px",
          }}
        />
      </Grid>

      {/* Navigation Buttons - Only visible on Desktop */}
      {!isMobile && (
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "flex-end", // Align to the right
            flex: 1,
          }}
        >
          {[{ label: "Home", url: "/" }, { label: "About us", url: "https://www.getmycollege.com/about-us" }, { label: "Pharmacy College", url: "https://www.getmycollege.com/" }, { label: "Contact Us", url: "https://www.getmycollege.com/contact-us" }].map((item, index) => (
            <Button
              key={index}
              onClick={() => handleNavigation(item.url)}
              style={{
                color: "black",
                fontSize: "18px", // Increased font size
                margin: "0 15px",
                textTransform: "none",
                position: "relative",
                padding: "10px 20px",
                transition: "color 0.3s ease",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#3f51b5"; // Change color to lighter blue on hover
                const spanElement = e.target.querySelector("span");
                if (spanElement) {
                  spanElement.style.width = "100%"; // Show underline on hover
                }
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "black"; // Revert color on mouse leave
                const spanElement = e.target.querySelector("span");
                if (spanElement) {
                  spanElement.style.width = "0"; // Hide underline on mouse leave
                }
              }}
            >
              {item.label}
              <span
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: 0,
                  height: "2px",
                  backgroundColor: "orange",
                  transition: "width 0.3s ease",
                }}
                className="hover-underline"
              />
            </Button>
          ))}
        </Grid>
      )}

      {/* Menu Icon for Mobile - Only visible on Mobile */}
      {isMobile && (
        <Grid item style={{ marginLeft: "auto" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => toggleDrawer(true)}
            style={{ color: "black" }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      )}

      {/* Drawer (Mobile Navigation) */}
      <Drawer anchor="left" open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <List style={{ width: 250 }}>
          {/* Logo and Close Icon at the top of the drawer */}
          <Grid container style={{ alignItems: "center", padding: "10px" }}>
            <Grid item>
              <img
                src={pharmalogo}
                alt="Logo"
                style={{
                  height: "50px",
                  marginRight: "15px",
                }}
              />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}></Grid>
            <Grid item>
              <IconButton edge="end" color="inherit" onClick={() => toggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider /> {/* Divider after logo */}

          {/* Menu Items with Icons */}
          {[{ text: "Home", url: "/", icon: <HomeIcon /> }, { text: "About Us", url: "https://www.getmycollege.com/about-us", icon: <InfoIcon /> }, { text: "Pharmacy College", url: "https://www.getmycollege.com/", icon: <LocalPharmacyIcon /> }, { text: "Contact Us", url: "https://www.getmycollege.com/contact-us", icon: <ContactMailIcon /> }].map((item, index) => (
            <ListItem button key={index} onClick={() => handleNavigation(item.url)}>
              <Grid container alignItems="center">
                <Grid item style={{ marginRight: "10px" }}>{item.icon}</Grid>
                <Grid item>
                  <ListItemText primary={item.text} />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <AutoPopup isOpen={isAutoPopupOpen} onClose={handleCloseAutoPopup} leadFlag="Organic" />
    </Grid>
  );
};

export default Npharm1;
